<template>
  <section class="filters">
    <b-form-group class="search-patient">
      <label for="search-by-patient">Paciente</label>
      <InputSearch
        id="search-by-patient"
        placeholder="Buscar paciente..."
        v-model="filters.patient"
        debounce="300"
      />
    </b-form-group>

    <b-form-group class="search-guide">
      <label for="search-by-guide">Nº da Guia</label>
      <multiselect
        v-model="filters.guideNumber"
        id="search-by-guide"
        placeholder="Selecionar..."
        :options="guideNumbers"
        :searchable="true"
        :allow-empty="true"
        :internal-search="true"
        :showLabels="false"
        :loading="loading"
        class="with-border"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown class="icon" />
          </div>
        </template>
        <template slot="noOptions">Nenhuma opção</template>
        <template slot="noResult">Nenhum resultado</template>
      </multiselect>
    </b-form-group>

    <b-form-group class="search-situation">
      <label for="search-by-situation">Situação</label>
      <multiselect
        id="search-by-situation"
        placeholder="Selecionar..."
        v-model="filters.situation"
        :options="situationsOptions"
        :searchable="true"
        :allow-empty="false"
        :showLabels="false"
        class="with-border"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noOptions">Nenhuma guia encontrada</template>
      </multiselect>
    </b-form-group>
  </section>
</template>
<script>
import api from '@/modules/tiss/manageGuides/api'

export default {
  name: 'guides-filter',
  components: {
    InputSearch: () => import('@/components/General/InputSearch'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  data() {
    return {
      loading: false,
      filters: {
        patient: null,
        situation: null,
        guideNumber: null
      },
      guideNumbers: [],
      originalRows: [],
      situations: [
        'Em aberto',
        'Pago',
        'Pago parcialmente',
        'Glosado',
      ]
    }
  },
  computed: {
    situationsOptions() {
      return ['Em aberto', 'Pago', 'Pago parcialmente', 'Glosado']
    }
  },
  async mounted() {
    await this.getTissGuideNumbers()
  },
  methods: {
    async getTissGuideNumbers() {
      this.loading = true
      try {
        const { data } = await api.getTissGuideNumbers(this.$route.params.batchId)
        this.guideNumbers = data.map(el => el.toString())
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    filters: {
      handler(value) {
        this.$emit('input', Object.assign({}, value))
      },
      deep: true
    }
  },
  
}
</script>